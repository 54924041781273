<template>
    <div>
        <b-overlay :show="!dataLoaded">
            <b-card title="Surveys">
                <delete-items-button v-if="$hasPermission($permissions.RemoveSurveys)" :selection="selection" @deleted="loadData" @reset=" selection.selected_item_ids = []"/>

                <b-button v-if="$hasPermission($permissions.AddSurveys)" variant="primary" @click="$router.push('/survey/add')" class="mr-1">
                    <feather-icon
                        icon="PlusIcon"
                        class="mr-50"
                    />
                    <span class="align-middle">Add survey</span>
                </b-button>


                <basic-table ref="basicTable"  :columns="columns" :data="formattedData" v-slot="props" @update-selection="updateSelection" @filter-update="filterUpdate">
                    <div v-if="props.column.displayType === 3 && $hasPermission($permissions.RemoveSurveys)" class="d-flex justify-content-center">
                        <b-form-checkbox  v-model="selection.selected_item_ids" :value="props.row.id"></b-form-checkbox>
                    </div>
                    <div v-else-if="props.column.displayType === 1" class="d-flex justify-content-center">
                        <b-button v-if="$hasPermission($permissions.ViewSurveys)" variant="success" @click="$router.push('/survey/' + props.row.id)" class="mr-1">
                            <feather-icon icon="EyeIcon"/>
                        </b-button>
                    </div>
                    <span v-else-if="props.column.displayType === 2" class="d-flex justify-content-center">
                        <b-badge v-if="props.row.enabled" variant="light-success">
                            Enabled
                        </b-badge>
                        <b-badge v-else variant="light-danger">
                            Disabled
                        </b-badge>
                    </span>
                    <span v-else class="d-flex justify-content-center">
                        {{ props.formattedRow[props.column.field] }}
                    </span>

                </basic-table>
            </b-card>
        </b-overlay>
    </div>
</template>
<script>

    import {BBadge, BButton, BCard, BOverlay, BFormCheckbox} from 'bootstrap-vue'
    import BasicTable from '@/views/components/BasicTable'
    import DeleteItemsButton from '@/views/components/DeleteItemsButton.vue'

    export default {
        components: {
            BCard,
            BButton,
            BasicTable,
            BBadge,
            BOverlay,
            BFormCheckbox,
            DeleteItemsButton

        },
        data() {
            return {
                dataLoaded: false,
                surveys: [],
                selection:{
                    selected_item_ids:[],
                    collection_name:''
                },
                columns: [
                    {
                        label: 'Select',
                        displayType: 3,
                        field: 'select',
                        sortable: false,
                        filterOptions: {
                            enabled: false
                        }
                    },
                    {
                        label: 'Name',
                        displayType: 0,
                        field: 'name',
                        filterOptions: {
                            enabled: true,
                            placeholder: 'Search'
                        }
                    },
                    {
                        label: 'Enabled',
                        displayType: 2,
                        field: 'enabled',
                        filterOptions: {
                            enabled: true,
                            placeholder: 'Search'
                        }
                    },
                    {
                        label: 'Actions',
                        displayType: 1,
                        field: 'actions',
                        sortable: false,
                        filterOptions: {
                            enabled: false
                        }
                    }
                ]
            }
        },
        methods: {

            loadData() {
                this.$refs.basicTable.$refs.table.reset()
                this.dataLoaded = false
                const thisIns = this
                const loadPromise = this.$http.get('/api/management/v1/survey')
                loadPromise.then(function(response) {
                    thisIns.surveys = response.data
                }).catch(function(error) {
                    thisIns.$printError((error.response) ? error.response.data : error)
                })

                Promise.all([loadPromise]).finally(function() {
                    thisIns.dataLoaded = true
                })
            },
            updateSelection(e) {
                if (this.$refs.basicTable.$refs.table.paginated[0] && this.$refs.basicTable.$refs.table.processedRows[0] && e) {
                    let arr = []
                    const pagResults = this.$refs.basicTable.$refs.table.paginated[0].children
                    const filterResults = this.$refs.basicTable.$refs.table.processedRows[0].children

                    arr = pagResults.filter(element => filterResults.includes(element))
                    arr.forEach((item) => {
                        const el =  this.selection.selected_item_ids.includes(item.id)
                        if (!el)   this.selection.selected_item_ids.push(item.id)
                    })
                } else {
                    this.selection.selected_item_ids = []
                }
            },
            filterUpdate(e) {
                e.forEach((item) => {
                    const el =  this.selection.selected_item_ids.includes(item.id)
                    if (el)   this.selection.selected_item_ids.splice(item.id)
                })
                this.$store.dispatch('app/updateSelectAll', false)
            }
        },

        computed: {
            formattedData() {
                return this.surveys.map((survey, index) => {
                    return {
                        index,
                        id: survey.id,
                        name: survey.name,
                        enabled: survey.enabled,
                        questions: survey.questions
                    }
                })
            }
        },
        mounted() {

            this.selection.collection_name = 'surveys'
            this.loadData()
        }
    }
</script>